@import "styles/constants";

.custom-chips {
  &.ant-tag {
    margin: 0;
    padding: 4px 8px;
    border: none;
    border-radius: 24px;
    background-color: $colorBlue20;
  }

  &.chips-menu {
    padding: 2px 6px;
    color: $colorYellow50;
    background-color: $colorYellow8;
  }
}

.grey-chips {
  &.ant-tag {
    background-color: $colorBlue10;
    border: solid 1px $colorBlue30;
    padding: 0 8px;
    color: $colorGray70;
  }
  &.text-12 {
    .typography {
      font-size: 12px !important;
    }
  }
}
