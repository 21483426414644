@import "styles/constants";

.highlighted-row-existed{
  .existed-record-info-icon-row{
    background-color: $colorYellow4;
  }
}
.existed-record-info-icon-row{
  svg{
    height: 18px;
    width: 18px;
  }
}
.contacts-duplicates-item-list-table.table-default .ant-table-tbody tr.ant-table-row.ant-table-row-level-0 td.ant-table-selection-column{
  display: none;
}
.contacts-duplicates-item-list-table{
  .ant-checkbox-wrapper{
    padding: 0 12px 0 0;
  }
  &.recompare{
    .expand-wrapper{
      padding: 0 0 0 16px !important;
      position: relative;
      top: 3px;
    }
  }
  .existed-record-info-icon{
    width: 16px;
    height: 16px;
    svg{
      width: 16px;
      height: 16px;
      path{
        stroke: $colorYellow50;
      }
    }
  }
  .ant-table-tbody {
    .information-wrapper{
      width: 40px;
      height: 40px;
    }
    td{
      &:has(.diff-cells){
        background: $colorBlue10 !important;
      }
    }
    .expand-wrapper{
      display: flex !important;
    }
    .ant-table-row-selected td{
      background-color: transparent !important;
    }
    tr.ant-table-row.ant-table-row-level-0 td {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px
    }

    .ant-table-cell.ant-table-selection-column{
      padding: 8px 0 8px 8px !important;
    }
    &.ant-table-tbody td{
      padding: 8px 4px;
    }
  }
}
