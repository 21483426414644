@import "styles/constants";

.custom-input {
  &.ant-form-item {
    .ant-form-item-label {
      line-height: 16px;
      text-align: left;
      padding-bottom: 4px;

      label {
        height: auto;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: $colorGray90;

        &.ant-form-item-required {
          &::before {
            display: none;
          }

          &::after {
            content: '*';
            display: block;
            margin: 0;
            color: $colorRed40;
          }
        }
      }
    }

    .ant-input-affix-wrapper,
    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled) {
      padding: 0;
      border-radius: 4px;
      border-color: $colorGray40;
      box-shadow: none;

      &:hover {
        border-color: $colorGray60;
      }

      &:has(input:focus) {
        border-color: $colorBlue60;
      }

      &:focus,
      &.ant-input-affix-wrapper-focused,
      &.ant-input-affix-wrapper-focused:hover {
        border-color: $colorBlue60;
        box-shadow: none;
      }

      &:active {
        border-color: $colorBlue70;
      }

      &:disabled {
        background-color: $colorGray10;
        border-color: $colorGray40;
        cursor: not-allowed;
      }

      &.ant-input-affix-wrapper-disabled {
        border-color: $colorGray40;
      }

      .ant-input-prefix {
        margin: 0 0 0 12px;
      }

      input {
        border: none;
      }
    }
  }
}

.ant-input-number {
  width: 100% !important;
  border: none !important;
  box-shadow: none !important;
}

.spinner-input-number{
  .ant-input-number-handler-wrap{
    opacity: 1;
  }
  .ant-input-number{
    &:not(.ant-input-number-status-error) {
      border: 1px solid $colorGray40 !important;
    }
    &.ant-input-number-status-error {
      border: 1px solid $colorRed40 !important;
    }
    border-radius: 4px !important;
    &:hover{
      border: 1px solid $colorGray60 !important;
    }
    &:focus{
      border: 1px solid $colorBlue60 !important;
    }
    &:active{
      border: 1px solid $colorBlue70 !important;
    }
    &:disabled  {
      border: 1px solid $colorGray40 !important;
    }
  }
  .ant-input-number-handler-up{
    position: absolute;
    top: 10px;
    height: 30%  !important;
  }
  .ant-input-number-handler-down{
    position: absolute;
    top: 20px;
    height: 30%  !important;
  }
  .ant-input-number-handler-up:hover{
    height: 30%  !important;
  }
  .ant-input-number-handler-down:hover{
    height: 30%   !important;
  }
  input,
  .ant-input-affix-wrapper > input.ant-input{
    border: none;
  }
  .ant-input-number-handler{
    border: none;
  }
}

.allow-clear{
  .ant-input{
    color: $colorGray100 !important;
  }
  .ant-input-suffix{
    height: 100%;
    top: 2px;
    right: 12px;
    svg{
      path{
        fill: $colorGray70;
      }
    }
  }
}
