@import "src/styles/constants";

.users-wrapper {
  flex: 1;
}

.text-wrapper {
  width: 84px;
  min-width: 84px;
  &--label {
    color: $colorGray70;
  }
}