@import 'src/styles/constants';
@import 'src/styles/breakpoint';
// @import '~antd/dist/antd.min.css';

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  ::-webkit-scrollbar-thumb {
    border-width: 0;
    border-radius: 6px;
    background-color: $colorGray40;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: $colorGray50;
  }

  ::-webkit-scrollbar-track {
    border-width: 0;
  }
}

html {
  overflow-y: hidden;
}

body {
  margin: 0;
  min-height: 100vh;
  padding: 0;
  font-family: $font-family, sans-serif !important;

  > * {
    padding: 0 !important;
  }
  @media only screen and (max-width: $screen-sm) {
    min-height: 100dvh;
  }
}

h1,
h2,
h3,
h4 {
  margin: 0;
  padding: 0;
}

a {
  outline: none;
  text-decoration: none;
}

button,
input {
  outline: none;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.ant-form-item {
  margin-bottom: 0 !important;
}

.ant-row {
  flex-direction: column !important;
}

.ant-input {
  box-shadow: none !important;
}

.ant-form-item-control-input {
  min-height: auto !important;
}
.rc-virtual-list-scrollbar-thumb{
  border-radius: 40px !important;
  background: $colorGray40 !important;
  &:hover{
    background: $colorGray50 !important;
  }
}
.ant-input-textarea-show-count.ant-input-textarea-in-form-item::after,
.ant-input-suffix {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  color: $colorGray60;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}

.ant-input-show-count-suffix {
  color: $colorGray60 !important;
}

.ant-col,
.ant-form-item-control-input,
.ant-input-affix-wrapper {
  position: static !important;
}
.mt-140 {
  margin-top: 140px !important;
}
.mt-120 {
  margin-top: 120px !important;
}
.mt-40 {
  margin-top: 40px !important;
}
.mt-36 {
  margin-top: 36px !important;
}

.mt-32 {
  margin-top: 32px !important;
}

.mt-24 {
  margin-top: 24px !important;
}
.mb-24 {
  margin-bottom: 24px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.mb-8 {
  margin-bottom: 8px !important;
}
.ml-24 {
  margin-left: 24px !important;
}
.mt-20 {
  margin-top: 20px !important;
}

.mt-16 {
  margin-top: 16px !important;
}

.mt-6 {
  margin-top: 6px !important;
}

.ml-16 {
  margin-left: 16px !important;
}

.mb-4 {
  margin-bottom: 4px !important;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.mt-12 {
  margin-top: 12px !important;
}

.ml-12 {
  margin-left: 12px !important;
}

.mt-8 {
  margin-top: 8px !important;
}

.mt-4 {
  margin-top: 4px !important;
}

.mt-0 {
  margin-top: 0 !important;
}
.mr-4 {
  margin-right: 4px !important;
}

.mr-72 {
  margin-right: 72px !important;
}

.mr-24 {
  margin-right: 24px !important;
}

.mr-12 {
  margin-right: 12px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.p-0 {
  padding: 0 !important;
}

.mr-8 {
  margin-right: 8px !important;
}

.p-16 {
  padding: 16px !important;
}

.p-8 {
  padding: 8px !important;
}

.column {
  display: flex;
  flex-direction: column;
}
.gap-40 {
  gap: 40px;
}
.gap-32 {
  gap: 32px;
}
.gap-24{
  gap: 24px;
}
.gap-16{
  gap: 16px;
}
.gap-12{
  gap: 12px;
}
.gap-10 {
  gap: 10px;
}
.gap-8{
  gap: 8px;
}
.gap-4 {
  gap: 4px;
}
.row {
  display: flex;
  flex-direction: row;
}
.space-between {
  justify-content: space-between;
}
.align-center {
  align-items: center;
}
.align-baseline {
  align-items: baseline;
}
.align-start {
  align-items: flex-start !important;
}
.align-end {
  align-items: flex-end;
}
.vertical-align-middle {
  vertical-align: middle !important;
}
.flex {
  display: flex;
}
.full-height{
  height: 100%;
}
.pointer {
  cursor: pointer;
}

.required {
  &::after {
    content: '*';
    color: $colorRed40
  }
}

.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.full-width{
  width: 100%;
}

.half-width {
  width: 50%;
}

.word-break{
  word-break: break-all;
}

.overflow-wrap-break-word {
  overflow-wrap: break-word;
}

.text-12 {
  font-size: 12px !important;
}

.bottom-notification.ant-notification-notice {
  width: 364px !important;
  border-radius: 8px !important;
  border: 1px solid $colorGray30;
  .ant-notification-notice-content {
    width: 100%;
  }
  .ant-notification-notice-description {
    margin-left: 35px !important;
  }
  .ant-notification-notice-icon .bottom-notification__icon {
      width: 20px !important;
      height: 20px !important;
      position: relative;
      top: 3px;
  }
}