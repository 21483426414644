@import "src/styles/constants";

.info-banner-with-title {
  row-gap: 13px;
  padding: 10px 16px;
  border-radius: 2px;
  width: 100%;
  background-color: $colorBlue60;
  color: $colorWhite;

  svg {
    width: 16px;
    height: 16px;
    path {
      fill: $colorWhite;
    }
  }
}
