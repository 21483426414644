@import "src/styles/constants";

.legend {
  &__title {
    width: 100%;
  }

  &__price {
    width: 100px;
    text-align: right;
    margin: unset !important;
    flex-shrink: 0;
  }

  &__part {
    width: 310px;
    color: $colorGray70;

    .square {
      width: 20px;
      height: 20px;
      flex-shrink: 0;
      border-radius: 2px;
    }

    .green {
      background-color: $colorGreen20;
    }

    .yellow {
      background-color: $colorYellow20;
    }

    .orange {
      background-color: $colorYellow40;
    }

    .blue {
      background-color: $colorBlue50;
    }

    .light_red {
      background-color: $colorRed10;
    }

    .red {
      background-color: $colorRed20;
    }

    .gray {
      background-color: $colorGray30;
    }

    .dark_gray {
      background-color: $colorGray50;
    }

    .accept-pay-subtitle {
      color: $colorGray70;
    }
  }
}
