@import "src/styles/constants";
@import "src/styles/breakpoint";

.container-portal_content-container__header{
  display: none;
  background-color: $colorBlue70;
  padding: 14px 32px;
  justify-content: space-between;
  position: fixed;
  align-items: center;
  z-index: 5;
  min-height: 68px;
  width: 100vw;
  @media only screen and (max-width: $screen-sm) {
    padding: 16px 8px 16px 16px;
    min-height: 56px;
    height: 56px;
  }
  &.open{
    width: 100vw;
    z-index: 2;
  }
  &__logo{
    width: unset;
    height: 28px;
    @media only screen and (max-width: $screen-sm) {
      width: unset;
      height: 24px;
    }
    &__close{
      path{
        fill: $colorWhite;
      }
    }
  }
  .custom-link {
    line-height: 0;
  }
  @media only screen and (max-width: $screen-md) {
    display: flex;
  }
}

.drawer-default{
  &.mobile-menu-portal-drawer {
    margin-top: 68px;
    outline-color: transparent !important;
    @media only screen and (max-width: $screen-sm) {
      margin-top: 56px;
    }
    .ant-drawer-mask {
      opacity: 1 !important;
      background-color: rgba(0, 0, 0, 0.4) !important;
    }
    .ant-drawer-content-wrapper {
      border-radius: 0;
      width: 272px;
      max-width: 272px;
      .container-portal__tabs{
        width: 272px;
        max-width: 272px;
        display: flex !important;
        .custom-link {
          display: none;
        }
      }
      max-height: calc(100dvh - 68px)!important;
      min-height: calc(100dvh - 68px) !important;
      box-shadow: none !important;
      .ant-drawer-header{
        display: none;
      }
      .ant-drawer-content {
        background-color: $colorBlue70;
      }
      .ant-drawer-body{
        overflow-x: hidden;
        padding: 0;
      }
      @media only screen and (max-width: $screen-sm) {
        width: 228px;
        max-width: 228px;
        min-height: calc(100dvh - 56px) !important;
        max-height: calc(100dvh - 56px) !important;
        .container-portal__tabs{
          width: 228px;
          max-width: 228px;
        }
      }
    }
  }
}