@import "styles/constants";


.business-details-container {
    .container__content {
        padding-bottom: 80px !important;
        &.details-content {
            height: unset !important;
            min-height: 100% !important;
        }
    }
}
.add-business-details-form{
    max-width: 752px;
    margin-bottom: 60px;
    .four-in-row{
        .ant-form-item{
            max-width: 170px;
        }
    }
    .description-row{
        .ant-form-item{
            max-width: 558px;
        }
        #description{
            resize: none;
        }
    }
    .three-in-row{
        .ant-form-item{
            max-width: 170px;
        }
    }
    .long-select{
        max-width: 364px !important;
    }
    .address-type{
        max-width: 170px !important;
    }
}