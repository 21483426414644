

.contract-details-general {
  display: flex;
}
.contract-details-general__details {
  max-width: 750px;
}

.add-responsible-popup__container{
  .ant-modal-body {
    overflow-y: visible !important;
  }
}

#contract-add-responsible{
  .field-with-label{
    .typography{
      &:nth-of-type(1){
        margin-bottom: 4px !important;
      }
    }
  }
}