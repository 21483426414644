@import "styles/constants";

.compliance-container{
  max-width: 1140px;
  &__header-filters{
    .ant-form-item{
      width: 260px;
    }
  }
  &__chart-wrapper{
    padding: 16px;
    border-radius: 8px;
    border: 1px solid $colorBlue20 !important;
    max-height: 304px;
    .chart__legend{
      gap: 14px;
    }
    .empty-state{
      .typography{
        color: $colorGray90;
      }
    }
    .on-contract-icon{
      position: relative;
      bottom: 4px;
      margin-left: 6px;
    }
    &.my-activity-container {
      padding: 16px;
    }
    &.spend-by-category{
      padding: 16px 0 16px 0;
      overflow-y: auto;
      .compliance-container__chart-wrapper__title{
        padding-left: 16px;
        padding-bottom: 12px;
      }
    }
    &.spend-by-product{
      max-width: 1140px !important;
      padding: 16px 0 0 0;
      overflow-y: auto;
      .compliance-container__chart-wrapper__title{
        padding-left: 16px;
        padding-bottom: 12px;
      }
      .ant-pagination{
        display: none;
      }
      table{
        max-width: 1120px;
      }
    }
    &__title{
      color: $colorGray70;
    }
    &.part{
      max-width: 558px !important;
    }
    .line-chart-compliance{
      padding: 8px 16px 8px 12px;
      border-top: 1px solid $colorBlue20;
      &__title{
        text-align: end;
        text-overflow: ellipsis !important;
        white-space: nowrap;
        display: inline-block;
        overflow: hidden;
      }
      &:nth-last-of-type(1){
        border-bottom: 1px solid $colorBlue20;
      }
      .led-by-indicator{
        flex-shrink: 0;
        .ant-avatar{
          width: 24px;
          height: 24px;
        }
        .ant-avatar-string{
          font-size: 10px;
          font-weight: 500;
          line-height: 16px;
        }
      }
    }
    .table__chart{
      width: 344px;
      min-width: 344px;
      &.spend-by-category{
        width: 286px;
        min-width: 286px;
      }
    }
  }
  .charts_ongoing-contracts_container{
    border: none;
    max-width: 1050px;
  }
  .period__date-picker{
    width: 260px;
  }
  .round-chart__reports {
    width: 204px;
    height: 204px;
  }
}
