@import "src/styles/constants";
.pending-approval-container{
  .sign-popup__logo{
    height: 184px;
  }
  .sign-popup-logo-wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
  }
}