@import "styles/constants";

.add-meeting-popup {
  &_location-select {
    position: relative;
    .ant-select .ant-select-selector {
      padding-left: 36px !important;
      .ant-select-selection-search {
        left: 36px;
      }
      .ant-select-selection-placeholder{
        left:36px;
      }
    }
    &_search-icon {
      position: absolute;
      top: 34px;
      left: 10px;
      z-index: 10;
    }
  }
}

.add-edit-event-calendar-form{
  .name-input{
    max-width: 528px !important;
    input{
      max-height: 40px;
    }
  }
  .timeline__time-selector{
    max-width: 218px !important;
  }
  .add-event-datepicker{
    max-width: 294px !important;
  }
  #summary{
    resize: none;
  }
}