@import 'src/styles/constants';

.contact-details-documents {
  display: flex;
  flex-direction: column;
}
.contact-details-documents__title {
  margin-top: 32px;
  margin-bottom: 24px;
}
.contact-details-documents__grid {
  display: grid;
  grid-template-columns: auto;
  grid-row-gap: 24px;
}
.contact-details-documents__row {
  display: grid;
  grid-template-columns: repeat(2, minmax(auto, 346px));
  grid-column-gap: 24px;
}

.add-signatory-modal {
  &__hint {
    color: $colorGray70;
  }
  .add-signatory-form {
    .calendar__modal__type-of-event-select {
      width: 100% !important;
      max-width: unset !important;
    }
  }
}