@import "src/styles/constants";

.error-banner {
  row-gap: 13px;
  padding: 10px 16px;
  border-radius: 2px;
  width: 100%;
  background-color: $colorRed4;

  &__content-text {
    color: $colorGray90;
  }
}
