@import "styles/styles";

.status-default {
  display: inline-block;
  padding: 4px 6px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: $colorGray90;
  border-radius: 4px;

  &--gray {
    background-color: $colorGray30;
  }

  &--yellow {
    color: $colorGray80;
    background-color: $colorYellow8;
  }

  &--red {
    color: $colorGray80;
    background-color: $colorRed10;
  }

  &--light-red {
    color: $colorGray80;
    background-color: $colorRed8;
  }

  &--green {
    color: $colorGray80;
    background-color: $colorGreen10;
  }

  &--lightblue {
    color: $colorGray80;
    background-color: $colorBlue30;
  }

  &--darkblue {
    color: $colorBlue10;
    background-color: $colorBlue70;
  }

  &--black {
    color: $colorGray10;
    background-color: $colorGray100;
  }

  &--darkgray {
    color: $colorGray10;
    background-color: $colorGray70;
  }

  &--orange {
    color: $colorGray80;
    background-color: $colorYellow30;
  }
}
