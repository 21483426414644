@import "src/styles/constants";
@import "src/styles/breakpoint";

.chat-history-empty {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.chat-history-empty__text {
  color: $colorGray50;
  text-align: center;
}
.chat-history-feature-list{
  padding: 0 32px 24px 24px;
  flex-direction: column-reverse !important;
  @media only screen and (max-width: $screen-sm) {
    padding: 0 16px 16px 16px;
  }
}

.message-item-container{
  row-gap: 4px;
  display: flex;
  flex-direction: column;
  align-items: start;
  &.revert{
    align-items: flex-end !important;
    .message-item-container__message{
      background-color: $colorBlue10;
      border-radius: 8px 0 8px 8px;
      order: 2;
      margin-right: 8px;
    }
    .message-item-container__wrapper{
      &__reply{
        left: 0;
        order: 1;
      }
      &.hidden{
        display: none;
      }
    }
    .message-item-container__author{
      justify-content: flex-end;

      &__current-time{
        order: 1;
      }
      .ant-avatar-group{
       order: 3;
      }
      &__name{
        order: 2;
        margin: 0 6px 0 12px;
      }
    }
    .message-item-container__attachments{
      &.none{
           .attachment{
             position: relative;
             &::after{
               content: url("../../../assets/svg/checkBlue16.svg");
               position: absolute;
               right: -23px;
               bottom: -7px;
             }
           }
         }

      &.read_by_members{
      .attachment{
        position: relative;
        &::after{
          content: url("../../../assets/svg/DoubleCheck16.svg");
          position: absolute;
          right: -23px;
          bottom: -7px;
        }
      }
    }}
    .attachments-list__files{
      &.chat{
        .attachment{
          background-color: $colorBlue10;
          border-radius: 8px 0 8px 8px;
          margin-right: 18px !important;
          margin-left: 0 !important;
        }

      }

    }
  }
  &__attachments{
    .attachments-list{
      .field-with-label{
        .typography-label{
          display: none;
        }
      }
    }
    .attachments-list__files{
      &.chat{
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
        border-bottom: none;
        padding-top: 0;
        padding-bottom: 0;
        .attachment{
          margin-left: 5px !important;
          margin-right: 0 !important;
          border-radius: 0 8px 8px 8px;
          padding: 8px 12px 8px 8px;
          background-color: #E4EFC4;
          .custom-button.ant-btn-link.button-default span{
            max-width: 219px !important;
            width: 219px !important;
          }
        }

      }

    }

  }
  .read-by-members{
    order: 3;
    display: flex;
    align-items: flex-end;
    svg{
      path{
        fill: $colorBlue70;
      }
    }
  }
  &__author{
    .ant-avatar{
      height: 24px;
      width: 24px;
      line-height: 24px;
      .ant-avatar-string{
        font-size: 10px;
        line-height: 20px;
      }
    }
    &__current-time{
      color: $colorGray70;
      text-transform: lowercase;
    }
    &__name{
      margin: 0 12px 0 6px;
    }
  }
  &__wrapper{
    position: relative;
    display: flex;
    @media only screen and (max-width: $screen-md) {
      .message-item-container__wrapper__reply{
        display: initial;
      }
    }
    &:hover{
      cursor: default;
      .message-item-container__wrapper__reply{
        display: initial;
      }

    }
    &__reply{
      display: none;
      position: absolute;
      right: 0;
      padding: 4px;
      border-radius: 2px;
      width: 24px;
      height: 24px;
      &:hover{
        background: $colorGray30;
      }
    }
  }
  &__message{
    width: fit-content;
    background-color: $colorGreen4;
    border-radius: 0 8px 8px 8px;
    padding: 8px;
    margin: 0 30px;
    p{
      white-space: pre-line;
      margin-bottom: 0;
    }
    .search-param{
      background-color: $colorYellow20;
    }
  }
}

.messages-wrapper-group{
  gap: 4px;
  display: flex;
  flex-direction: column;
  &__time{
    color: $colorGray50;
    text-align: center;
    margin: 24px 0;
  }
}

.unread-marker {
  width: 100%;
  height: 1px;
  margin-top: 8px;
  background-color: $colorGray30;
}

#message-tooltip-reply{
  top: 16px;
  right: -6px;
}
