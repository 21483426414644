@import "src/styles/constants";

.password-strength {
  &__wrapper {
    height: 6px;
    border: 1px solid $colorGray50;
    border-radius: 4px;
  }

  &__indicator {
    height: 100%;
    width: 1%;
    transition: .3s width ease;

    &--weak {
      width: 15%;
      background: linear-gradient(90.37deg, #D12027 1.56%, #E39300 276.12%, #E39300 505.34%, #80BC00 706.47%);
    }

    &--medium {
      width: 55%;
      background: linear-gradient(90deg, #D12027 0.93%, #E39300 73.72%, #E39300 134.48%, #80BC00 187.81%);
    }

    &--strong {
      width: 100%;
      background: linear-gradient(90deg, #D12027 0.93%, #E39300 39.52%, #E39300 71.73%, #80BC00 100%);
    }
  }

  .typography {
    margin-top: 2px;
    color: $colorGray60;
  }
}