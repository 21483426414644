@import "styles/constants";
.categories-responsible{
  .responsible{
    .name-container__row{
      .typography{
        &.typography-body-1{
          max-width: 180px !important;
          width: 100%;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          text-align: left;
        }

      }
    }
  }

}
.categories-responsible-cell{
  color: $colorBlue70;
  border-bottom: none;
}
.categories-responsible-popover{
  .ant-popover-inner-content{
    width: 461px;
    padding: 16px 24px 32px;
    .custom-link{
      padding: 8px;
      display: flex;
      flex-wrap: wrap;
      &:hover{
        background-color: $colorBlue10;
        color: $colorBlue90;
      }
    }
    .flex-item {
      flex: 0 1 auto;
      min-width: 0;
      white-space: nowrap;
    }
    .led-by-indicator{
      flex-shrink: 0;
      .ant-avatar{
        width: 24px;
        height: 24px;
      }
      .ant-avatar-string{
        font-size: 10px;
        font-weight: 500;
        line-height: 16px;
      }
    }
  }

}
