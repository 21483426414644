@import "styles/constants";

.charts {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 25px;
  margin-top: 24px;
  max-height: 333px;
  margin-bottom: 32px;

  &_titles {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-bottom: 24px;
  }
  &_title {
    font-family: $font-family;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: $colorGray90;

    &__icon {
      width: 20px;
      height: 20px;
      border-radius: 50%;

      &:hover {
        background-color: $colorYellow4;
      }

      svg {
        width: 18px;
        height: 18px;
      }
    }
  }

  &_subtitle {
    font-family: $font-family;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $colorGray60;
  }

  &_actual-savings {
    font-family: $font-family;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: $colorBlue60;
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    min-width: 320px;
    margin-top: -90px;
    gap: 50px;

    &_start, &_end {
      width: 100%;
      text-align: center;
    }
  }

    &_actual-savings_chart {
      margin-top: -120px;
    }

    &_actual-savings_chart-container {
      display: flex;
      flex-direction: column;
      border: 1px solid $colorBlue20;
      border-radius: 8px;
      width: 100%;
      padding: 24px 16px 24px 24px;
      .actual-saving-menu{
        position: relative;
        svg{
          z-index: 99;
        }
      }
    }

    &_actual-savings_chart-inner {
      max-width: 320px;
      max-height: 200px;
      margin: auto;
    }

    &_ongoing-contracts {
      &_container {
        max-height: 333px;
        display: flex;
        flex-direction: column;
        border: 1px solid $colorBlue20;
        border-radius: 8px;
        padding: 24px 16px;
        width:100%;
        max-width: 1200px;
      }
      &_total_container {
        display: flex;
        flex-direction: row;
        gap: 12px;
        margin-top: 24px;
        margin-bottom: 8px;
        align-items: baseline;
      }
      &_total {
        font-family: $font-family;
        font-style: normal;
        font-weight: 500;
        font-size: 48px;
        line-height: 64px;
        color: $colorBlue90;
      }
      &_total-title {
        font-family: $font-family;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: $colorGray70;
      }

      &_chart {
        display: flex;
        flex-direction: row;
        gap: 2px;
        width: 100%;
      }

      &_chart-part {
        height: 12px;
        border-radius: 2px;
      }

      &_conventions {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        width: 100%;
        gap: 12px;
        margin-top: 18px;
      }

      &_convention-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
      }

      &_convention {
        display: flex;
        flex-direction: column;
        gap: 8px;

        svg {
          min-width: 16px;
        }
      }

      &_convention_color-count {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
      }

      &_convention_color {
        height: 16px;
        width: 16px;
        border-radius: 2px;
      }

      &_convention_count {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: $colorGray70;
      }

      &_convention_name {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: $colorGray70;
        max-width: 77px;
        overflow-wrap: normal;
    }
  }
}

.expirings-title {
  font-family: $font-family;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: $colorGray90;
  margin-top: 32px;
  margin-bottom: 32px;
}

.non-contract__finish_date{
  display: none;
}
.actual-savings-menu{
  min-width: 228px;
}