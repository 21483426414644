@import "src/styles/constants";

.description-cell {
  &__text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    border-bottom: 1px solid $colorBlue70;
    cursor: pointer;
  }

  &__close {
    border: none;
    background-color: $colorWhite;
    height: 24px;
  }

  &__content-header {
    padding: 24px 32px 8px 32px;
    display: flex;
    justify-content: flex-end;

    .description-cell__content-header__content__caption {
      color: $colorGray70;
    }

    svg {
      cursor: pointer;
    }
  }

  &__files {
    display: flex;
    gap: 24px;
  }
}

.ant-popover {
  padding: 0 !important;

  .ant-popover-inner {
    max-width: 676px;
    border-radius: 8px;
    box-shadow: $boxShadow_1;
  }

  .ant-popover-title {
    padding: 0;
    border: none;
  }

  .ant-popover-inner-content {
    padding: 16px 32px 32px;
    color: $colorGray90;
    font-size: 16px;
    line-height: 24px;
  }

  .ant-popover-arrow {
    display: none;
  }
}
