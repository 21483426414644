//colors


$colorBlue100: #0B263F;
$colorBlue90: #103861;
$colorBlue80: #1A5099;
$colorBlue70: #2767A9;
$colorBlue60: #407EC9;
$colorBlue50: #5B8FCE;
$colorBlue40: #759FD6;
$colorBlue30: #AABFEB;
$colorBlue20: #D0DEF5;
$colorBlue10: #E9EEF7;
$colorBlue5Alpha: #0F71AE0D;

$colorGray100: #1D2229;
$colorGray90: #363C43;
$colorGray80: #545A62;
$colorGray70: #6D747D;
$colorGray60: #898C8E;
$colorGray50: #C2C3C4;
$colorGray40: #D3D8DE;
$colorGray30: #E1E7ED;
$colorGray20: #F0F3F7;
$colorGray10: #FAFBFC;

$colorRed50: #7E0A10;
$colorRed40: #A01417;
$colorRed30: #D12027;
$colorRed20: #E5565C;
$colorRed10: #F38B8D;
$colorRed8: #FBB8B8;
$colorRed4: #FDEBEC;

$colorGreen50: #336600;
$colorGreen40: #508700;
$colorGreen30: #80BC00;
$colorGreen20: #9CD652;
$colorGreen10: #C3DD8C;
$colorGreen4: #E4EFC4;

$colorYellow50: #A66900;
$colorYellow40: #C47E00;
$colorYellow30: #E39300;
$colorYellow20: #FFB71B;
$colorYellow10: #FFDA96;
$colorYellow8: #FFEBC2;
$colorYellow4: #FFF7E6;

$colorOrange50: #9A4700;
$colorOrange40: #B75A11;
$colorOrange30: #D26718;
$colorOrange20: #E37623;
$colorOrange10: #F39B5A;
$colorOrange8: #FFBA90;
$colorOrange4: #FFE6D7;

$colorCyan50: #00475B;
$colorCyan40: #00687F;
$colorCyan30: #007B98;
$colorCyan20: #0083AD;
$colorCyan10: #3C9EBC;
$colorCyan8: #66AEC8;
$colorCyan4: #87BFD5;

$colorSky50: #117A8C;
$colorSky40: #209AB0;
$colorSky30: #3CB5CD;
$colorSky20: #54C8E8;
$colorSky10: #87D9ED;
$colorSky8: #B3E3F3;
$colorSky4: #E1F6FA;

$colorCoral50: #9C2A2F;
$colorCoral40: #C3383D;
$colorCoral30: #E74D53;
$colorCoral20: #F67A74;
$colorCoral10: #FCAAA4;
$colorCoral8: #FFD1CD;
$colorCoral4: #FFF0EF;

$colorWhite: #FFF;
$colorBlack: #000;


//gradients
$blueLinearGradient: linear-gradient(180deg, #23568C 0%, #2767A9 100%);


//shadows

$boxShadow_1: 0 4px 6px 2px rgba(77, 91, 102, 0.12);
$boxShadow_2:  4px 4px 28px 6px rgba(51, 116, 163, 0.12);

$height-sm: calc(100dvh - 56px) !important;
$height-md: calc(100dvh - 68px) !important;
$height-sm-without-header: 100dvh !important;

$font-family: 'Open Sans';



