@import "styles/constants";

.calendar__modal__type-of-event-select{
  max-width: 302px !important;
  &__rendered-options {
    position: relative;
    .additional-label-text {
      position: relative;
      &:after {
        position: absolute;
        content: '(add me as a signatory)';
        color: $colorGray70;
      }
    }
    .ant-select-item {
      &:last-of-type {
        padding: 0;
        height: 0;
      }
    }
    .calendar__modal__type-of-event-select__add-type-option-container {
      padding: 8px 12px;
    }
  }
}


.calendar-wrapper {
  &__content {
    flex: 1;
    overflow-y: auto;
  }
}