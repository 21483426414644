@import "src/styles/constants";

.user-info-text {
  color: $colorBlue70
}

.user-info-wrapper {
  align-items: flex-start;
}

.rfp-details-responsible-user__avatar {
  svg {
    height: 32px;
    width: 32px;
  }
}