@import "styles/constants";

.rebates {
  &__date-filter {
    max-width: 170px;
  }

  &__container {
    padding: 24px 16px;
    border: 1px solid $colorBlue20;
    border-radius: 8px;
  }

  &__chart {
    width: 164px;
    height: 164px;
  }

  &__chart-container {
    column-gap: 40px;
  }

  &__legend {
    display: grid;
    grid-gap: 12px 40px;
    grid-auto-flow: column;
    grid-template-rows: repeat(3, 24px);
  }
}

.financials-table {
  .ant-table-tbody {
    .ant-table-row {
      td.ant-table-cell {
        &:has(.red-cells) {
          background: $colorRed4 !important;
        }

        &:has(.yellow-cells) {
          background: $colorYellow4 !important;
        }
      }
    }
  }
}
